<template>
  <card class="shadow-none" id="graficos">
    <template v-slot:headerTitle>
      <span class="card-title my-2 h4"><b>Graficos de Resultados</b></span>
    </template>
    <div class="d-flex justify-content-end">
      <span class="p-2">
        <i
          :class="`ri-layout-grid-line h3 pointer ${
            layout === 1 ? 'text-danger' : ''
          }`"
          @click="handleLayout()"
        ></i>
        <i
          :class="`ri-layout-row-line h3 pointer ${
            layout === 2 ? 'text-danger' : ''
          }`"
          @click="handleLayout()"
        ></i>
      </span>
    </div>
    <div class="row pt-4 mx-3 justify-content-center" style="gap: 0.3rem">
      <slot />
    </div>
  </card>
</template>

<script>
import { mapState } from "vuex";

export default {
  methods: {
    handleLayout() {
      this.$store.dispatch("reportesApex/changeLayout");
    },
  },
  computed: {
    ...mapState("reportesApex", ["layout"]),
  },
};
</script>
