<template>
  <div :class="`col-12 ${layout === 1 ? 'col-lg-4' : ''} bg-dark p-1 rounded`">
    <div
      v-if="tiposCargos.length > 0"
      id="irregular-timeseries"
      :style="{
        height: layout === 2 ? '500px' : '300px',
        width: '100%',
      }"
    ></div>
    <template v-else>
      <p
        class="d-flex flex-column align-items-center justify-content-center"
        style="min-height: 300px"
      >
        <i class="fas fa-spinner fa-spin mb-2" style="font-size: 1.5rem"></i>
        Cargando gráfico...
      </p>
    </template>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import { mapState } from "vuex";

export default {
  data() {
    return {
      chart: null,
    };
  },
  async mounted() {
    this.$isLoading(true);

    await this.$store.dispatch("reportesApex/getTiposCargos");
    this.setupChart();
    this.$isLoading(false);
  },
  computed: {
    ...mapState("reportesApex", ["tiposCargos", "layout"]),
  },
  watch: {
    tiposCargos: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    setupChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      // Datos ficticios para los niveles de cargo
      // const data = [
      //   { nivel: "Nivel A", promedio: 43.5 },
      //   { nivel: "Nivel B", promedio: 32.8 },
      //   { nivel: "Nivel C", promedio: 41.2 },
      //   { nivel: "Nivel D", promedio: 58.0 },
      //   { nivel: "Nivel E", promedio: 89.1 },
      // ];

      const options = {
        series: [
          {
            name: "Promedio",
            data: this.tiposCargos.map((item) => item.promediovaloracion),
          },
        ],
        chart: {
          type: "area",
          stacked: false,
          height: "100%",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        xaxis: {
          categories: this.tiposCargos.map((item) => item.tipocargo),
          labels: {
            style: {
              colors: "#FFFFFF",
              fontSize: "8px",
            },
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
          labels: {
            style: {
              colors: "#FFFFFF",
            },
          },
        },
        title: {
          text: "Resultados por nivel de cargo",
          align: "left",
          offsetX: 14,
          style: {
            color: "#FFFFFF",
            fontSize: "16px",
          },
        },
        tooltip: {
          shared: true,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetX: -10,
        },
        responsive: [
          {
            breakpoint: 700,
            options: {
              chart: {
                width: "100%",
                height: 300,
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "10px",
                  },
                },
              },
            },
          },
        ],
      };

      this.chart = new ApexCharts(
        document.querySelector("#irregular-timeseries"),
        options
      );
      this.chart.render();
    },
    updateChart() {
      if (this.chart) {
        this.chart.updateOptions({
          series: [
            {
              data: this.tiposCargos.map((item) => item.promediovaloracion),
            },
          ],
          xaxis: {
            categories: this.tiposCargos.map((item) => item.tipocargo),
          },
        });
      } else {
        this.setupChart();
      }
    },
    beforeDestroy() {
      if (this.chart) {
        this.chart.destroy();
      }
    },
  },
};
</script>

<style scoped></style>
