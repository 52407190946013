<template>
  <div :class="`col-12 ${layout === 1 ? 'col-lg-7' : ''} bg-dark p-1 rounded `">
    <div
      v-if="departamentos.length > 0"
      id="distributed-columns"
      :style="{
        height: layout === 2 ? '500px' : '300px',
        width: '100%',
      }"
    ></div>
    <template v-else>
      <p
        class="d-flex flex-column align-items-center justify-content-center"
        style="min-height: 300px"
      >
        <i class="fas fa-spinner fa-spin mb-2" style="font-size: 1.5rem"></i>
        Cargando gráfico...
      </p>
    </template>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import { mapState } from "vuex";

export default {
  data() {
    return {
      chart: null,
    };
  },
  async mounted() {
    this.$isLoading(true);
    await this.$store.dispatch("reportesApex/getDepartamentos");
    this.setupChart();
    this.$isLoading(false);
  },
  computed: {
    ...mapState("reportesApex", ["departamentos", "layout"]),
  },
  watch: {
    departamentos: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    setupChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      const colors = [
        "#26A0FC",
        "#26E7A6",
        "#FEBC3B",
        "#FF6178",
        "#8B75D7",
        "#69808A",
        "#46B3A9",
        "#D830EB",
      ];

      const departamentosMap = this.departamentos.reduce((acc, item) => {
        acc[item.departamento] = item.departamentoid;
        return acc;
      }, {});

      const options = {
        series: [
          {
            data: this.departamentos.map((item) => item.promediovaloracion),
          },
        ],
        chart: {
          height: "100%",
          type: "bar",
          foreColor: "#FFFFFF",
          events: {
            dataPointSelection: async (event, chartContext, config) => {
              const selectedLabel =
                config.w.config.xaxis.categories[config.dataPointIndex];
              const selectedDepartamentoId = departamentosMap[selectedLabel];
              if (selectedDepartamentoId !== this.previousDepartamentoId) {
                // console.log("DepartamentoId:", selectedDepartamentoId);
                await this.$store.dispatch(
                  "reportesApex/getTiposCargos",
                  selectedDepartamentoId
                );
                await this.$store.dispatch(
                  "reportesApex/getCargos",
                  selectedDepartamentoId
                );
                this.previousDepartamentoId = selectedDepartamentoId;
              } else {
                await this.$store.dispatch("reportesApex/getTiposCargos");
                await this.$store.dispatch("reportesApex/getCargos");
              }
            },
          },
        },
        colors: colors,
        title: {
          text: "Resultados por departamento",
          align: "left",
          offsetX: 14,
          style: {
            color: "#FFFFFF",
            fontSize: "16px",
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: this.departamentos.map((item) => item.departamento),
          labels: {
            style: {
              colors: colors,
              fontSize: "8px",
            },
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
          labels: {
            style: {
              colors: ["#FFFFFF"],
            },
          },
        },
        responsive: [
          {
            breakpoint: 700,
            options: {
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };

      this.chart = new ApexCharts(
        document.querySelector("#distributed-columns"),
        options
      );
      this.chart.render();
    },
    updateChart() {
      if (this.chart) {
        this.chart.updateOptions({
          series: [
            {
              data: this.departamentos.map((item) => item.promediovaloracion),
            },
          ],
          xaxis: {
            categories: this.departamentos.map((item) => item.departamento),
          },
        });
      } else {
        this.setupChart();
      }
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style scoped></style>
