<template>
  <card>
    <div class="custom-container">
      <!-- aqui se mostrara los graficos -->
      <container-charts>
        <polar-area />
        <distributed-columns />
        <irregular-timeseries />
        <distributed-columns-cargos />
      </container-charts>
    </div>
  </card>
</template>

<script>
import containerCharts from "./container-charts.vue";
import polarArea from "./charts/polar-area.vue";
import distributedColumns from "./charts/distributed-columns.vue";
import distributedColumnsCargos from "./charts/distributed-columns-cargos.vue";
import irregularTimeseries from "./charts/irregular-timeseries.vue";

export default {
  components: {
    containerCharts,
    polarArea,
    distributedColumns,
    irregularTimeseries,
    distributedColumnsCargos,
  },
  name: "ReporteGraficos",
};
</script>
