<template>
  <div :class="`col-12 ${layout === 1 ? 'col-lg-4' : ''} bg-dark p-1 rounded `">
    <div
      v-if="sucursales.length > 0"
      id="polar-area"
      :style="{
        height: layout === 2 ? '500px' : '300px',
        width: '100%',
      }"
    ></div>
    <template v-else>
      <p
        class="d-flex flex-column align-items-center justify-content-center"
        style="min-height: 300px"
      >
        <i class="fas fa-spinner fa-spin mb-2" style="font-size: 1.5rem"></i>
        Cargando gráfico...
      </p>
    </template>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import { mapState } from "vuex";

export default {
  data() {
    return {
      chart: null,
    };
  },
  async mounted() {
    this.$isLoading(true);

    await this.$store.dispatch("reportesApex/getSucursales");
    this.setupChart();
    this.$isLoading(false);
  },
  computed: {
    ...mapState("reportesApex", ["sucursales", "layout"]),
  },
  methods: {
    setupChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      // const data = [
      //   { sucursal: "Sucursal A", promedioValoracion: 43.5 },
      //   { sucursal: "Sucursal B", promedioValoracion: 32.8 },
      //   { sucursal: "Sucursal C", promedioValoracion: 41.2 },
      //   { sucursal: "Sucursal D", promedioValoracion: 58.0 },
      //   { sucursal: "Sucursal E", promedioValoracion: 89.1 },
      // ];
      const sucursalesMap = this.sucursales.reduce((acc, item) => {
        acc[item.sucursal] = item.sucursalid;
        return acc;
      }, {});
      const options = {
        chart: {
          type: "polarArea",
          height: "100%",
          // foreColor: "#FFFFFF",
          events: {
            dataPointSelection: async (event, chartContext, config) => {
              const selectedLabel =
                config.w.config.labels[config.dataPointIndex];
              const selectedSucursalId = sucursalesMap[selectedLabel];

              if (selectedSucursalId !== this.previousSucursalId) {
                // console.log("SucursalId:", selectedSucursalId);
                await this.$store.dispatch(
                  "reportesApex/getDepartamentos",
                  selectedSucursalId
                );
                this.previousSucursalId = selectedSucursalId;
              } else {
                await this.$store.dispatch("reportesApex/getDepartamentos");
              }
            },
          },
        },
        labels: this.sucursales.map((item) => item.sucursal),
        series: this.sucursales.map((item) => item.promediovaloracion),
        title: {
          text: "Resultados por empresa",
          align: "left",
          offsetX: 14,
          style: {
            color: "#FFFFFF",
            fontSize: "16px",
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };

      this.chart = new ApexCharts(
        document.querySelector("#polar-area"),
        options
      );
      this.chart.render();
    },
  },
};
</script>

<style scoped></style>
